<template>
  <div v-if="vacancyData">
    <b-row>
      <b-col
        cols="21"
        xl="8"
      >
        <b-card>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Nama</span>
              </th>
              <td class="pb-50">
                {{ vacancyData.name ? vacancyData.name : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 text-capitalize">
                <span class="badge badge-light-primary">
                  {{ vacancyData.status ? vacancyData.status : '-' }}
                </span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Kategori</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ vacancyData.category.name ? vacancyData.category.name : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Tenggat Waktu</span>
              </th>
              <td class="pb-50">
                {{ vacancyData.due_date ? vacancyData.due_date : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Lokasi</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ vacancyData.location ? vacancyData.location : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="DollarSignIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Harga</span>
              </th>
              <td class="pb-50">
                {{ vacancyData.price | formatAmount }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UsersIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Jumlah Pelamar</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ vacancyData.applicant_count ? vacancyData.applicant_count : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Waktu</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ vacancyData.created_at ? vacancyData.created_at : '-' }}
              </td>
            </tr>
          </table>
          <div>
            <div class="d-flex align-items-center mb-1">
              <div>
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Deskripsi :</span>
              </div>
            </div>
            <p class="mb-0 text-justify">
              {{ vacancyData.description ? vacancyData.description : '-' }}
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="21"
        xl="4"
        class="d-flex justify-content-between flex-column"
      >
        <b-card>
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="vacancyData.user.photo_url"
              :text="vacancyData.user.name != null ? vacancyData.user.name.charAt(0) : '-'"
              :variant="`light-danger`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h3>Owner</h3>
                <h4 class="mb-0">
                  {{ vacancyData.user.name }}
                </h4>
                <span class="card-text">{{ vacancyData.user.email }}</span>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="21" xl="12">
        <b-card class="table-responsive">
          <table class="table b-table table-fixed w-100">
            <thead>
              <tr>
                <th>Talenta</th>
                <th>Status</th>
                <th>Harga</th>
                <th>Waktu Pengerjaan</th>
                <th>Waktu Dibuat</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
              <td
                colspan="6"
                class="text-center"
              >
                <b-spinner
                  class="mb-1"
                  variant="primary"
                /><br>
                Loading...
              </td>
            </tr>
              <tr v-for="applicant in vacancyData.applicants.data" :key="applicant.uuid">
                <td>
                  <router-link :to="`/talenta-detail/${applicant.talent.uuid}`">
                    {{ applicant.talent.name ? applicant.talent.name : '-' }}
                  </router-link>
                </td>
                <td>
                  <span
                    class="badge text-capitalize badge-pill"
                    :class="
                      applicant.status == 'active'
                        ? 'badge-light-success'
                        : 'badge-light-secondary'
                    "
                  >{{ applicant.status ? applicant.status : '-' }}</span>
                </td>
                <td>
                  {{ applicant.price_range ? applicant.price_range : '-' }}
                </td>
                <td>
                  {{ applicant.vacancy_application_time ? applicant.vacancy_application_time : '-' }}
                </td>
                <td>
                  {{ applicant.created_at ? applicant.created_at : '-' }}
                </td>
              </tr>
              <tr v-if="vacancyData.applicants.total == 0 && !isLoading">
              <td
                colspan="5"
                class="text-center"
              >
                Data is empty.
              </td>
            </tr>
            </tbody>
          </table>
          <div
            v-if="vacancyData.applicants.total > 0"
            class="m-1"
          >
            <div class="row">
              <div class="col mb-1">
                <small>Showing {{ vacancyData.applicants.from }} to {{ vacancyData.applicants.to }} from {{ vacancyData.applicants.total }}</small>
              </div>
              <div class="col">
                <pagination
                  :data="vacancyData.applicants"
                  :limit="4"
                  align="right"
                  @pagination-change-page="getData"
                />
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  data() {
    return {
      id: this.$route.params.id,
      vacancyData: null,
      isLoading: false,
    }
  },
  created() {
    this.loadData()
    // this.getData()
  },
  methods: {
    loadData() {
      this.$http.get(`/admin/vacancies/${this.id}`).then(response => {
        this.vacancyData = response.data.data
      })
    },
    getData(page = 1) {
      this.isLoading = true

      this.$http.get(`/admin/vacancies/${this.id}`, {
        params: {
          page,
        },
      })
        .then(response => {
          this.vacancyData = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style>

</style>
